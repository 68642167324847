import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'firebase/compat/firestore';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from './firebase';
import { auth } from './firebase';
import { Modal as BootstrapModal } from 'bootstrap';
import emailjs from 'emailjs-com';

const TvSearch = ({ toggleShowMovies }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [selectedTv, setSelectedTv] = useState(null);
  const [searchTitle, setSearchTitle] = useState('Popular TV Shows');
  
  const [error, setError] = useState(null);
  const modalRef = useRef(null);
  const bsModalRef = useRef(null);
  const mediaIcon = <svg aria-hidden="true" class="" fill="" height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg"><path d="M6 5H42C42.7957 5 43.5587 5.31607 44.1213 5.87868C44.6839 6.44129 45 7.20435 45 8V34C45 34.7957 44.6839 35.5587 44.1213 36.1213C43.5587 36.6839 42.7957 37 42 37H6C5.20435 37 4.44129 36.6839 3.87868 36.1213C3.31607 35.5587 3 34.7957 3 34V8C3 7.20435 3.31607 6.44129 3.87868 5.87868C4.44129 5.31607 5.20435 5 6 5ZM6 34H42V8H6V34Z" fill="currentColor" fill-rule="evenodd"></path><path d="M36 43V40H12V43H36Z" fill="currentColor"></path></svg>


  // Function for fetching popular movies
  const fetchPopularMovies = async () => {
    setError(null);
  
    try {
      const response = await axios.get(`https://api.themoviedb.org/3/tv/popular?api_key=${process.env.REACT_APP_MOVIE_DB_API_KEY}`);
      setResults(response.data.results);
    } catch (error) {
      setError(error.message);
    }
  };  

  

  const search = async (e) => {
    e.preventDefault();
    const response = await axios.get(`https://api.themoviedb.org/3/search/tv?api_key=${process.env.REACT_APP_MOVIE_DB_API_KEY}&query=${query}`);
    setResults(response.data.results);
  }

  const fetchMovieDetails = async (id) => {
    setError(null);  
    try {
        const responseMovieDetails = axios.get(`https://api.themoviedb.org/3/tv/${id}?api_key=${process.env.REACT_APP_MOVIE_DB_API_KEY}`);
        //const responseRating = axios.get(`https://api.themoviedb.org/3/tv/${id}/first_air_dates?api_key=${process.env.REACT_APP_MOVIE_DB_API_KEY}`);
    
        const [movieDetails] = await Promise.all([responseMovieDetails]);
        //const USRelease = rating.data.results.find(release => release.iso_3166_1 === 'US');
        //const certification = USRelease ? USRelease.first_air_dates[0].certification : 'Not available';
        setSelectedTv({ ...movieDetails.data });
        console.log(movieDetails.data)
    
      } catch (error) {
        setError(error.message);
      }
  };

  useEffect(() => {
    if(modalRef.current) {
      bsModalRef.current = new BootstrapModal(modalRef.current);
      if (selectedTv) {
        bsModalRef.current.show();
      } else {
        bsModalRef.current.hide();
      }
    }
  }, [selectedTv]);  

  const formatRuntime = (minutes) => {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    return `${h}h ${m}m`;
  };

  // Call fetchPopularMovies when the component is mounted
  useEffect(() => {
    fetchPopularMovies();
  }, []);

  const sendEmail = (tvshow) => {
    const createdBy = (auth.currentUser.email === null ? "Guest" : auth.currentUser.email);

    emailjs.send('service_te1lj98', 'template_28qae3o', {
      message: `${createdBy} added ${tvshow.title} to the list!`,
      to_name: `${tvshow.title}`,
      from_name: `${createdBy}`
    }, '-HvSRC_p_KQhrSd5q')
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
    }, (err) => {
      console.log('FAILED...', err);
    });
  }

  // Function to add tvshow to Firestore
  const addMovie = async (tvshow, comment='') => {
    const user = auth.currentUser;
    const createdBy = (auth.currentUser.email === null ? "Guest" : auth.currentUser.email);
    if (user) {
      try {
        await addDoc(collection(db, "listItems"), {
          name: tvshow.name,
          movieId: tvshow.id,
          releaseDate: tvshow.first_air_date.split('-')[0],
          comments: comment,
          createdAt: serverTimestamp(),
          createdBy: createdBy,
          poster: tvshow.poster_path,
          backdropPath: tvshow.backdrop_path,
          mediaType: "TV",
        });
        // After successfully adding the movie, send an email
        sendEmail(tvshow);
      } catch (error) {
        console.error("Error adding tvshow: ", error);
      }
    }
  };  

  return (
    <div>
        <h1 className="searchTitle">{mediaIcon} {searchTitle}</h1>
        <form className="row mb-2 searchRow" onSubmit={search}>  
            <div className="col-auto">
                <input type="text" className="form-control mediaSearchBar" placeholder="Search for a tv show" value={query} onChange={(e) => setQuery(e.target.value)} />
            </div>
            <div className="col-auto">
              <div className="btn-toolbar" role="toolbar" aria-label="">
                <div className="btn-group me-2 mb-2" role="group" aria-label="">
                    <button type="submit" className="btn btn-primary" disabled={!query} onClick={() => {setSearchTitle('Show results for: ' + query)}} >Search</button>
                </div>
                <div className="btn-group me-4 mb-2" role="group" aria-label="">
                    <button type="button" className="btn btn-primary" onClick={() => {setQuery('');setSearchTitle('Popular TV Shows'); fetchPopularMovies();}}>Clear</button>
                </div>
                <div className="btn-group me-4 mb-2" role="group" aria-label="">
                    <button type="button" className="btn btn-primary toggleUnselected" onClick={toggleShowMovies}>Movies</button>
                    <button type="button" className="btn btn-primary toggleSelected" onClick={toggleShowMovies}>TV</button>
                </div>
              </div>
            </div>            
        </form>

        <div className='row'>
            {results.length > 0 ? (
            results.map((tvshow) => (                                
                <div className='col-sm-6 col-lg-4 col-xl-3 col-xxl-2 d-flex align-items-stretch mb-4 d-flex justify-content-center' key={tvshow.id}>
                    <div className="card d-flex flex-column" style={{ width: "18rem" }} >
                        <img role="button" src={`https://image.tmdb.org/t/p/w500/${tvshow.poster_path}`} className="card-img-top" alt={tvshow.title} onClick={() => fetchMovieDetails(tvshow.id)}/>
                        <div className="card-body movieCard">
                            <h5 className="card-title" role="button" onClick={() => fetchMovieDetails(tvshow.id)}>{tvshow.title} <span style={{fontSize: '0.8em', fontWeight: 'normal'}}> ({tvshow.first_air_date.split('-')[0]})</span></h5>

                            <div className="btn-toolbar" role="toolbar" aria-label="">
                                <div className="btn-group me-1 mb-2 btn-group-sm" role="group" aria-label="">
                                    <button className="btn btn-primary btn-sm" onClick={() => fetchMovieDetails(tvshow.id)}><i className="bi bi-info-circle"></i></button>
                                </div>
                                <div className="btn-group me-1 mb-2 btn-group-sm" role="group" aria-label="">
                                    <button type="button" className="btn btn-primary btn-sm addMovie" onClick={() => addMovie(tvshow)}>Add to List</button>
                                </div>
                            </div>
                      
                            {error && <p>Error: {error}</p>}
                        </div>
                    </div>
                

                    {selectedTv && (
                    <div className="modal" tabIndex="-1" ref={modalRef}>
                        <div className="modal-dialog modal-lg movieModal">
                            <div className="modal-content">

                                <div className="modal-body movieDetailsWrap" style={{backgroundImage: `url(https://image.tmdb.org/t/p/original/${selectedTv.backdrop_path})`,backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                                    <div className="movieDetails">
                                        <div className="row">
                                            <div className="col-4">
                                                <img className="img-fluid rounded-start" src={`https://image.tmdb.org/t/p/w500/${selectedTv.poster_path}`} alt={selectedTv.title} />
                                            </div>
                                            <div className="col-8">
                                                <h1>{selectedTv.name}</h1>
                                                <div className="minorInfo">
                                                <p>
                                                    <span>{tvshow.first_air_date.split('-')[0]}</span>
                                                    {selectedTv.certification && selectedTv.certification !== "Not available" && (
                                                        <span className="rating">{selectedTv.certification}</span>
                                                    )}
                                                    {selectedTv.runtime > 0 && (
                                                        <span>{formatRuntime(selectedTv.runtime)}</span>
                                                    )}
                                                </p>
                                                    <p>{selectedTv.genres.map(genre => genre.name).join(', ')}</p>
                                                    <p>{selectedTv.vote_average} / 10 - {new Intl.NumberFormat().format(selectedTv.vote_count)} votes</p>
                                                    <p>Seasons - {selectedTv.number_of_seasons}</p>
                                                    <p>Episodes - {selectedTv.number_of_episodes}</p>
                                                </div>
                                                <p className="overview">
                                                    {selectedTv.overview}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="btn-toolbar modal-btn-toolbar" role="toolbar" aria-label="">
                                            <div className="btn-group me-1 mb-2 btn-group-sm" role="group" aria-label="">
                                                <button type="button" className="btn btn-primary btn-sm addMovie" onClick={() => addMovie(selectedTv)} data-bs-dismiss="modal">Add to List</button>
                                            </div>
                                            <div className="btn-group me-1 mb-2 btn-group-sm" role="group" aria-label="">
                                                <button type="button" className="btn btn-secondary btn-sm closeBtn" data-bs-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="overlay"></div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                )}
            </div>
            ))) : (
              <div className="col-12">
                  <h2>Nothing found</h2>
              </div>
          )}
        </div>
    </div>
  );
}

export default TvSearch;
