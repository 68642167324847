import { signInWithPopup, GoogleAuthProvider, signInAnonymously } from "firebase/auth"; // import required modules
import { auth } from './firebase';

const LoginPage = () => {
    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: 'select_account'
        });
        try {
            await signInWithPopup(auth, provider);
        } catch (err) {
            console.error(err);
        }
    }

    const guestLogin = async () => {
        try {
            await signInAnonymously(auth);
        } catch (err) {
            console.error(err);
        }
    }

    return (         
        <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                <div className="card border-0 shadow rounded-3 my-5">
                <div className="card-body p-4 p-sm-5">
                    <h5 className="card-title text-center mb-5 fw-light fs-5">Sign In</h5>
                    <form>              
        
                    <div className="d-grid mb-2">
                        <div role="button" className="btn btn-google btn-login text-uppercase fw-bold btn-warning" onClick={guestLogin}>
                            <i className="bi bi-person"></i> Continue as Guest
                        </div>
                        <p className="loginText">You can't get email notifications when logging in as guest. Also can't delete and add comments to items you add to the list</p>
                        <hr className="my-4" />
                        <div role="button" className="btn btn-google btn-login text-uppercase fw-bold btn-danger" onClick={signInWithGoogle}>
                            <i className="bi bi-google"></i> Sign in with Google
                        </div>
                        <p className="loginText">Using google login you will get an email when the movie you added to the list is added to Plex server. Can also delete your selection and add comments.</p>
                    </div>
                    </form>
                </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
