import React, { useState } from "react";
import TodoList from './TodoList';
import MovieSearch from './movieSearch'
import TvSearch from './tvSearch';

const Content = () => {
    const [showMovies, setShowMovies] = useState(true);

    const toggleShowMovies = () => {
      setShowMovies(!showMovies);
    }

  return (
        <div className="row">
            <div className="col-4 listCol offcanvas-sm offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasResponsive" aria-labelledby="offcanvasResponsiveLabel">
                <div className="d-sm-none toggleOffCanv" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasResponsive" aria-controls="offcanvasResponsive">
                    View List
                </div>
                <div className="offcanvas-body">
                    <div>
                        <TodoList />
                    </div>
                </div>
            </div>
            <div className="col movieCol">
                {showMovies ? 
                    <MovieSearch toggleShowMovies={toggleShowMovies} /> :
                    <TvSearch toggleShowMovies={toggleShowMovies} />
                }
            </div>
        </div>
  )
}

export default Content;
