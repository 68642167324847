import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDNjzqwFgJdJbZXa2-2w-voeDkJ_wmbgJo",
    authDomain: "plex-wish-list.firebaseapp.com",
    databaseURL: "https://plex-wish-list-default-rtdb.firebaseio.com",
    projectId: "plex-wish-list",
    storageBucket: "plex-wish-list.appspot.com",
    messagingSenderId: "53873497273",
    appId: "1:53873497273:web:57c5654edef6fe1960f768",
    measurementId: "G-7F8VZKFCCN"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);