import React, { useState, useEffect } from 'react';
import { collection, deleteDoc, doc, query, orderBy, onSnapshot, updateDoc } from "firebase/firestore";
import { db, auth } from './firebase';
import emailjs from 'emailjs-com';

const TodoList = () => {
  // Use State hooks to declare and initialize state variables
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [doneModalVisible, setDoneModalVisible] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState("");

  useEffect(() => {
    // Get the current user's email and set it to the currentUser state
    setCurrentUser(auth.currentUser.email === null ? "Guest" : auth.currentUser.email);
    
    // Set loading to true to show the loading screen while fetching data
    setLoading(true);
    setError(null);

    // Define a query to get a list of items ordered by createdAt in descending order
    const q = query(collection(db, "listItems"), orderBy("createdAt", "desc"));

    // Listen for real-time updates in Firestore and update the items state accordingly
    const unsubscribe = onSnapshot(q, snapshot => {
      // Create an array of items from the snapshot and set it to the items state
      const newItems = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setItems(newItems);
      setLoading(false);
    }, err => {
      setError(err.message);
      setLoading(false);
    });

    // Unsubscribe from the snapshot listener when the component is unmounted
    return unsubscribe;
  }, []);

  // Delete an item from Firestore
  const deleteItem = async (id) => {
    try {
      await deleteDoc(doc(db, "listItems", id));
    } catch (err) {
      setError(err.message);
    }
  };

  // Update or add a comment to an item in Firestore
  const addOrUpdateComment = async () => {
    try {
      const itemRef = doc(db, "listItems", selectedItem.id);
      const commentsArray = [...selectedItem.comments, newComment];
      await updateDoc(itemRef, { comments: commentsArray });
      setModalVisible(false);
      setNewComment('');
    } catch (err) {
      setError(err.message);
    }
  };

  // Open the comment modal and set the selected item and the newComment state
  const openModal = (item) => {
    setSelectedItem(item);
    setNewComment(item.comments[item.comments.length - 1] || "");
    setModalVisible(true);
  };

  // Close the comment modal
  const closeModal = () => setModalVisible(false);

  // Send an email to notify the creator of the item that the item has been added and delete the item
  const markItemAsDone = () => {
    if (currentUser !== "Guest") {
      const templateParams = {
        from_name: 'willsplexserver32@gmail.com',
        to_email: selectedItem.createdBy,
        to_name: selectedItem.createdBy,
        subject: `"${selectedItem.name}" has been added!`,
        message: `"${selectedItem.name}" has been added to the Plex server.`,
        additionalInfo: `${additionalInfo}`
      };
  
      emailjs.send('service_te1lj98', 'template_gl1tovb', templateParams, '-HvSRC_p_KQhrSd5q')
        .then((result) => {
            // This part will execute regardless of whether the current user is a guest or not
            deleteItem(selectedItem.id).then(() => {
              console.log('Document successfully deleted!');
            }).catch((error) => {
              console.error('Error removing document: ', error);
            });
            setDoneModalVisible(false);
            setAdditionalInfo("");
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      // If the current user is a guest, simply delete the item without sending an email
      deleteItem(selectedItem.id).then(() => {
        console.log('Document successfully deleted!');
      }).catch((error) => {
        console.error('Error removing document: ', error);
      });
      setDoneModalVisible(false);
      setAdditionalInfo("");
    }
  };
  

  // Open the done modal and set the selected item
  const openDoneModal = (item) => {
    setSelectedItem(item);
    setDoneModalVisible(true);
  };
  


  return (
    <div>
      <h1>Full Wish List</h1>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}

      <div className="userMovieList">
        {!loading && items.map((item, index) => (
          <div className="card mb-3" key={index}>
            <div className="row g-0">
              <div className="col-3">
              <img src={`https://image.tmdb.org/t/p/w500/${item.poster}`} className="img-fluid posterThumb" alt="..."/>
              </div>
              <div className="col-9">
                <div className="card-body">
                  <h5 className="card-title mTitle">{item.name}</h5>
                  <p className="card-text mReleaseDate mb-0">({item.releaseDate})</p>
                  <div class={"mediaIcon " + ((item.mediaType === 'TV') ? 'tvIcon' : 'movieIcon')} ></div>                  
                  {(item.comments === "") ? "" : <div className="card-text mComments"><h6>Comments:</h6><span>{item.comments}</span><br/></div>}
                  </div>
              </div>

               
                  

                      {(currentUser !== 'Guest' && (currentUser === 'william.klus@gmail.com' || item.createdBy === currentUser)) && 
                        <div className={"movieItemEditBtns " + ((currentUser === 'william.klus@gmail.com') ? 'adminView' : '')}>
                          {//<p>{item.createdBy === currentUser && "(added by you)"}</p>
                          }


                          <div className="btn-group editBtns">
                            <button type="button" className="btn btn-primary addComment" onClick={() => openModal(item)}><i className="bi bi-chat-square-dots"></i></button>
                            <button type="button" className="btn btn-primary deleteItem" onClick={() => deleteItem(item.id)}><i className="bi bi-trash3"></i></button>
                          </div>

                          {//only i can see done button                              
                            }
                            {(currentUser === 'william.klus@gmail.com') ? 
                            <>
                            <div className="btn-group adminBtns">
                              <button type="button" className="btn btn-primary" onClick={() => openDoneModal(item)}>Done</button>
                              <button type="button" className="btn btn-primary" >N/A</button>
                            </div>
                            <div className="submitterEmail">{item.createdBy}</div>
                            </> 
                            : ""} 

                          
                        </div>
                      }

            </div>
          </div>
        ))}
      </div>

      {modalVisible && ( 
        <div className={`modal commentModal ${modalVisible ? 'active' : ''}`} tabIndex="-1">
          <div className="modal-dialog modal-lg movieModal">
            <div className="modal-content">
              <div className="modal-body movieDetailsWrap" style={{backgroundImage: `url(https://image.tmdb.org/t/p/original/${selectedItem.backdropPath})`,backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                <div className="movieDetails">
                  <div className="row">
                    <div className="col-4">
                      <img src={`https://image.tmdb.org/t/p/w500/${selectedItem.poster}`} className="img-fluid rounded-start" alt={selectedItem.name} />
                    </div>
                    <div className="col-8">
                      <h1>{selectedItem.name}</h1>
                      <br/>
                      <h6>Comment:</h6>
                      <textarea value={newComment} onChange={e => setNewComment(e.target.value)} />
                      <div className="btn-toolbar modal-btn-toolbar" role="toolbar" aria-label="">
                        <div className="btn-group me-1 mb-2 btn-group-sm" role="group" aria-label="">
                          <button className="btn btn-primary" onClick={addOrUpdateComment}>
                            {selectedItem.comments.length > 0 ? 'Update Comment' : 'Add Comment'}
                          </button>
                        </div>
                        <div className="btn-group me-1 mb-2 btn-group-sm" role="group" aria-label="">
                          <button className="btn btn-primary" onClick={closeModal}>Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
                <div className="overlay"></div>
              </div>
            </div>
          </div>
        </div>
      )}

      {doneModalVisible && (
        <div className={`modal commentModal ${doneModalVisible ? 'active' : ''}`} tabIndex="-1">
          <div className="modal-dialog modal-lg movieModal">
            <div className="modal-content">
              <div className="modal-body">
                <div className="movieDetails">
                  <div className="row">
                    <h4>Mark as Done</h4>
                    <textarea value={additionalInfo} onChange={e => setAdditionalInfo(e.target.value)} placeholder="Additional Information..."/>

                    <div className="btn-toolbar modal-btn-toolbar" role="toolbar" aria-label="">
                      <div className="btn-group me-1 mb-2 btn-group-sm" role="group" aria-label="">
                        <button className="btn btn-primary" onClick={markItemAsDone}>Send Email and Delete</button>
                      </div>
                      <div className="btn-group me-1 mb-2 btn-group-sm" role="group" aria-label="">
                        <button className="btn btn-primary" onClick={() => setDoneModalVisible(false)}>Cancel</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};
export default TodoList;
