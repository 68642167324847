import React, { useEffect, useState } from 'react';
import { auth } from './components/firebase';
import LoginPage from './components/LoginPage';
import Content from './components/Content';
import Header from './components/Header';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.css';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const logout = () => {
    auth.signOut().then(() => {
      console.log('User signed out');
    }).catch((error) => {
      console.log('Error signing out: ', error);
    });
  }

  return (
    <div>
      <Header user={user} logout={logout} />
      <div className="container-fluid">
        {user ? <Content /> : <LoginPage />}
      </div>
    </div>
  );
}

export default App;
