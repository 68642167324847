import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'firebase/compat/firestore';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from './firebase';
import { auth } from './firebase';
import { Modal as BootstrapModal } from 'bootstrap';
import emailjs from 'emailjs-com';

const MovieSearch = ({ toggleShowMovies }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [searchTitle, setSearchTitle] = useState('Popular Movies');
  
  const [error, setError] = useState(null);
  const modalRef = useRef(null);
  const bsModalRef = useRef(null);
  const mediaIcon = <svg aria-hidden="true" class="_1duebfh2e _1duebfhb rkbrtb0 rkbrtb2 rkbrtb9" fill="currentColor" height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M6 3H42C42.7957 3 43.5587 3.31607 44.1213 3.87868C44.6839 4.44129 45 5.20435 45 6V42C45 42.7957 44.6839 43.5587 44.1213 44.1213C43.5587 44.6839 42.7957 45 42 45H6C5.20435 45 4.44129 44.6839 3.87868 44.1213C3.31607 43.5587 3 42.7957 3 42V6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3ZM6 42H9L9 38H6V42ZM36 42H12V25H36V42ZM39 42H42V38H39V42ZM39 35H42V30H39V35ZM39 27H42V22H39V27ZM39 19H42V14H39V19ZM39 11H42V6H39V11ZM36 6L12 6L12 22H36V6ZM6 6L9 6V11H6L6 6ZM6 14H9V19H6L6 14ZM6 22H9V27H6L6 22ZM6 30H9V35H6L6 30Z" fill="currentColor" fill-rule="evenodd"></path></svg>
  

  // Function for fetching popular movies
  const fetchPopularMovies = async () => {
    setError(null);
  
    try {
      const response = await axios.get(`https://api.themoviedb.org/3/movie/popular?api_key=${process.env.REACT_APP_MOVIE_DB_API_KEY}`);
      setResults(response.data.results);
    } catch (error) {
      setError(error.message);
    }
  };  

  

  const search = async (e) => {
    e.preventDefault();
    const response = await axios.get(`https://api.themoviedb.org/3/search/movie?api_key=${process.env.REACT_APP_MOVIE_DB_API_KEY}&query=${query}`);
    setResults(response.data.results);
  }

  const fetchMovieDetails = async (id) => {
    setError(null);  
    try {
        const responseMovieDetails = axios.get(`https://api.themoviedb.org/3/movie/${id}?api_key=${process.env.REACT_APP_MOVIE_DB_API_KEY}`);
        const responseRating = axios.get(`https://api.themoviedb.org/3/movie/${id}/release_dates?api_key=${process.env.REACT_APP_MOVIE_DB_API_KEY}`);
    
        const [movieDetails, rating] = await Promise.all([responseMovieDetails, responseRating]);
        const USRelease = rating.data.results.find(release => release.iso_3166_1 === 'US');
        const certification = USRelease ? USRelease.release_dates[0].certification : 'Not available';
        setSelectedMovie({ ...movieDetails.data, certification });
        console.log(movieDetails.data)
    
      } catch (error) {
        setError(error.message);
      }
  };

  useEffect(() => {
    if(modalRef.current) {
      bsModalRef.current = new BootstrapModal(modalRef.current);
      if (selectedMovie) {
        bsModalRef.current.show();
      } else {
        bsModalRef.current.hide();
      }
    }
  }, [selectedMovie]);  

  const formatRuntime = (minutes) => {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    return `${h}h ${m}m`;
  };

  // Call fetchPopularMovies when the component is mounted
  useEffect(() => {
    fetchPopularMovies();
  }, []);

  const sendEmail = (movie) => {
    const createdBy = (auth.currentUser.email === null ? "Guest" : auth.currentUser.email);

    emailjs.send('service_te1lj98', 'template_28qae3o', {
      message: `${createdBy} added ${movie.title} to the list!`,
      to_name: `${movie.title}`,
      from_name: `${createdBy}`
    }, '-HvSRC_p_KQhrSd5q')
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
    }, (err) => {
      console.log('FAILED...', err);
    });
  }

  // Function to add movie to Firestore
  const addMovie = async (movie, comment='') => {
    const user = auth.currentUser;
    const createdBy = (auth.currentUser.email === null ? "Guest" : auth.currentUser.email);
    if (user) {
      try {
        await addDoc(collection(db, "listItems"), {
          name: movie.title,
          movieId: movie.id,
          releaseDate: movie.release_date.split('-')[0],
          comments: comment,
          createdAt: serverTimestamp(),
          createdBy: createdBy,
          poster: movie.poster_path,
          backdropPath: movie.backdrop_path,          
          mediaType: "MOVIE",
        });
        // After successfully adding the movie, send an email
        sendEmail(movie);
      } catch (error) {
        console.error("Error adding movie: ", error);
      }
    }
  };  

  return (
    <div>
        <h1 className="searchTitle">{mediaIcon} {searchTitle}</h1>
        <form className="row mb-2 searchRow" onSubmit={search}>  
            <div className="col-auto">
                <input type="text" className="form-control mediaSearchBar" placeholder="Search for a movie" value={query} onChange={(e) => setQuery(e.target.value)} />
            </div>
            <div className="col-auto">
              <div className="btn-toolbar" role="toolbar" aria-label="">
                <div className="btn-group me-2 mb-2" role="group" aria-label="">
                    <button type="submit" className="btn btn-primary" disabled={!query} onClick={() => {setSearchTitle('Movie results for: ' + query)}}>Search</button>
                </div>
                <div className="btn-group me-4 mb-2" role="group" aria-label="">
                    <button type="button" className="btn btn-primary" onClick={() => {setQuery('');setSearchTitle('Popular Movies'); fetchPopularMovies();}}>Clear</button>
                </div>
                <div className="btn-group me-4 mb-2" role="group" aria-label="">
                    <button type="button" className="btn btn-primary toggleSelected" onClick={toggleShowMovies}>Movies</button>
                    <button type="button" className="btn btn-primary toggleUnselected" onClick={toggleShowMovies}>TV</button>
                </div>
                
              </div>
            </div>            
        </form>

        <div className='row'>
            {results.length > 0 ? (
            results.map((movie) => (                                
                <div className='col-sm-6 col-lg-4 col-xl-3 col-xxl-2 d-flex align-items-stretch mb-4 d-flex justify-content-center' key={movie.id}>
                    <div className="card d-flex flex-column" style={{ width: "18rem" }} >
                        <img role="button" src={`https://image.tmdb.org/t/p/w500/${movie.poster_path}`} className="card-img-top" alt={movie.title} onClick={() => fetchMovieDetails(movie.id)}/>
                        <div className="card-body movieCard">
                            <h5 className="card-title" role="button" onClick={() => fetchMovieDetails(movie.id)}>{movie.title} <span style={{fontSize: '0.8em', fontWeight: 'normal'}}> ({movie.release_date.split('-')[0]})</span></h5>

                            <div className="btn-toolbar" role="toolbar" aria-label="">
                                <div className="btn-group me-1 mb-2 btn-group-sm" role="group" aria-label="">
                                    <button className="btn btn-primary btn-sm" onClick={() => fetchMovieDetails(movie.id)}><i className="bi bi-info-circle"></i></button>
                                </div>
                                <div className="btn-group me-1 mb-2 btn-group-sm" role="group" aria-label="">
                                    <button type="button" className="btn btn-primary btn-sm addMovie" onClick={() => addMovie(movie)}>Add to List</button>
                                </div>
                            </div>
                      
                            {error && <p>Error: {error}</p>}
                        </div>
                    </div>
                

                    {selectedMovie && (
                    <div className="modal" tabIndex="-1" ref={modalRef}>
                        <div className="modal-dialog modal-lg movieModal">
                            <div className="modal-content">

                                <div className="modal-body movieDetailsWrap" style={{backgroundImage: `url(https://image.tmdb.org/t/p/original/${selectedMovie.backdrop_path})`,backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                                    <div className="movieDetails">
                                        <div className="row">
                                            <div className="col-4">
                                                <img className="img-fluid rounded-start" src={`https://image.tmdb.org/t/p/w500/${selectedMovie.poster_path}`} alt={selectedMovie.title} />
                                            </div>
                                            <div className="col-8">
                                                <h1>{selectedMovie.title}</h1>
                                                <div className="minorInfo">
                                                <p>
                                                    <span>{movie.release_date.split('-')[0]}</span>
                                                    {selectedMovie.certification && selectedMovie.certification !== "Not available" && (
                                                        <span className="rating">{selectedMovie.certification}</span>
                                                    )}
                                                    {selectedMovie.runtime > 0 && (
                                                        <span>{formatRuntime(selectedMovie.runtime)}</span>
                                                    )}
                                                </p>
                                                    <p>{selectedMovie.genres.map(genre => genre.name).join(', ')}</p>
                                                    <p>{selectedMovie.vote_average} / 10 - {new Intl.NumberFormat().format(selectedMovie.vote_count)} votes</p>
                                                </div>
                                                <p className="overview">
                                                    {selectedMovie.overview}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="btn-toolbar modal-btn-toolbar" role="toolbar" aria-label="">
                                            <div className="btn-group me-1 mb-2 btn-group-sm" role="group" aria-label="">
                                                <button type="button" className="btn btn-primary btn-sm addMovie" onClick={() => addMovie(selectedMovie)} data-bs-dismiss="modal">Add to List</button>
                                            </div>
                                            <div className="btn-group me-1 mb-2 btn-group-sm" role="group" aria-label="">
                                                <button type="button" className="btn btn-secondary btn-sm closeBtn" data-bs-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="overlay"></div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                )}
            </div>
            ))) : (
              <div className="col-12">
                  <h2>Nothing found</h2>
              </div>
          )}
        </div>
    </div>
  );
}

export default MovieSearch;
