import React, { useState } from 'react';
import logo from "../assets/img/plex_icon_130854.png";
import { Navbar, Nav, NavDropdown, Container, Button, Modal } from 'react-bootstrap';

const Header = ({ user, logout }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <header>
            <Navbar className="plex-navbar" expand="lg">
                <Container fluid>
                    <Navbar.Brand>
                        <img src={logo} alt="" width="24" height="24" /> Plex Wish List
                    </Navbar.Brand>

                    {user && (
                        <Nav className="ms-auto mb-2 mb-lg-0 profile-menu">
                            <div className="nav-controls">
                                <Button className="me-3" onClick={handleShow}>
                                    <i className="bi bi-info-circle-fill"></i>
                                </Button>

                                <NavDropdown
                                    id="navbarDropdown"
                                    title={<i className="bi bi-person-circle"></i>}
                                    menuVariant="dark"
                                >
                                    <NavDropdown.Item disabled>{user?.email || "Guest"}</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={logout}>
                                        <i className="bi bi-door-closed"></i> Log Out
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </div>

                            <Modal show={show} onHide={handleClose} className="infoModal">                                
                                <Modal.Body>
                                    <h1>Page Info <i className="bi bi-info-circle"></i></h1>
                                    <br/>
                                    <div>This is a single list being edited by everyone. You will have the ability to delete and also add a comment to your submistions.</div>
                                    <br/>
                                    <div>When the movie is added to the plex server you will get an email comfirming it has been added.</div>
                                    <br/>
                                    <div>Also note that new movie releases will need to wait till its avaiable for digital download to be added to the Plex server.</div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={handleClose}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Nav>
                    )}
                </Container>
            </Navbar>
        </header>
    );
};

export default Header;
